// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', HelveticaNeue, 'Roboto', sans-serif;

  --ion-background-color: #fbfcfd;
  --ion-light-gray-bg: #fdfeff;
  --ion-dark-gray-header: #465667;
  --ion-mid-gray-bg: rgb(242 245 247);
  --ion-toolbar-background: #ffffff;

  /** primary **/
  --ion-color-primary: #206cde;
  --ion-color-primary-rgb: 54, 116, 207;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3066b6;
  --ion-color-primary-tint: #4a82d4;

  /** secondary **/
  --ion-color-secondary: #ffa23f;
  --ion-color-secondary-rgb: 255, 142, 22;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #ca90ff;
  --ion-color-tertiary-rgb: 202, 144, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #1ea454;
  --ion-color-success-rgb: 30, 164, 84;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #daac00;
  --ion-color-warning-rgb: 218, 172, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-item-background: #ffffff;
  --ion-color-step-0: #ffffff;
  --ion-color-step-50: #f7f7f7;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-200: #d8d8d8;
  --ion-color-step-500: #808080;
  --ion-color-step-600: #646464;
  --ion-color-step-700: #545454;
  --ion-color-step-800: #373737;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --input-border-color: #d5e6ed;
  --input-border-radius: 8px;

  --ion-border-color: #efefef;
  --ion-subtle-border-color: #f1f3f5;

  --card-border-color: #d5e6ed;
  --card-shadow-color: #c7cfd640;
  --depth-button-hover: #787d813d;

  --table-background: #ffffff;
  --left-nav-background: #ffffff;
  --left-nav-highlighted-item-rgb: 244, 244, 244;
  --sidebar-background: #ffffff;
  --highlighted-pill-background: #ffffff;
  --text-bubble-background: #ffffff;
  --text-bubble-private-background: #e4effd;

  --ion-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);

  .logo-light {
    display: none;
  }
  .logo-dark {
    display: block;
  }
}

@media (prefers-color-scheme: light) {
  /*
   * Light Colors
   * -------------------------------------------
   *

  body {
    --ion-color-step-50: #f6f6f6;
  }
  */
}

[data-theme='dark'] {
  color-scheme: dark;

  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #ff7a00;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #06224c;
  --ion-color-light-rgb: 6, 34, 76;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-background-color: rgb(8, 17, 31);
  --ion-background-color-rgb: 8, 17, 31;
  --ion-light-gray-bg: #030b18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-0: #000000;
  --ion-color-step-50: #151e2d;
  --ion-color-step-100: #272c3b;
  --ion-color-step-150: #303030;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #4b4a4a;
  --ion-color-step-300: #515151;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #949aae;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #c4c4c4;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #08111f;
  --ion-item-background: #0e182b;
  --ion-item-background-activated: #26344f;
  --ion-backdrop-opacity: 0.75;

  --input-border-color: #354056;
  --ion-border-color: #272c3b;
  --ion-subtle-border-color: #192336;

  --card-border-color: #1c293d;
  --card-shadow-color: #13151f;
  --depth-button-hover: #383d74;

  --table-background: #0e182b;
  --left-nav-background: #030b18;
  --left-nav-highlighted-item-rgb: 25, 36, 56;
  --sidebar-background: #0e182b;
  --highlighted-pill-background: #2c4068;
  --text-bubble-background: #111d32;
  --text-bubble-private-background: #312153;

  --ion-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.719);

  .logo-light {
    display: block;
  }
  .logo-dark {
    display: none;
  }
}
