@import "../../themes/ionic.globals.md";

// Material Design Menu
// --------------------------------------------------

/// @prop - Background of the menu
$menu-md-background:            $background-color !default;

/// @prop - Box shadow of the menu
$menu-md-box-shadow:            4px 0px 16px rgba(0, 0, 0, 0.18) !default;
